:root {
  scrollbar-color: #ccc var(--red);
  scrollbar-gutter: auto;
  scrollbar-width: auto;
}

/* width */
::-webkit-scrollbar {
  width: 0.7rem;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: var(--white);
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #ccc; 
  border-radius: 1rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--red); 
}