/*-- Footer --*/
footer {
    padding: 1.5rem 3rem;
    margin: 0 !important;
    border-top: 1px var(--whiteborder) solid;
    border-bottom: 1px var(--whiteborder) solid;
    background-color: var(--white);
    color: var(--gray);
    display: grid;
    grid-template-columns: 1.2fr 1fr 1fr 1.2fr;
    grid-template-rows: 1fr auto;
}

.footer-brand-img {
    width: auto;
    height: 100px;
    /* Adjust to fit your logo */
    margin: -0.2rem auto auto 2.5rem;
    background-repeat: no-repeat;
}
/*-- /Footer --*/

/*-- Footer headers --*/
footer div h3 {
    font-size: 1.6rem;
}
/*-- /Footer headers --*/

/*-- Footer Links --*/
.footer-links {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
    text-align: left;
}

.footer-links a,
.footer-links div a {
    font-size: 1.2rem;
    color: var(--black);
    text-decoration: none;
    margin-bottom: 1.5rem;
    transition: all 0.3s ease;
}

.footer-links a:hover,
.footer-links div a:hover {
    color: var(--red);
    text-decoration: none;
    cursor: pointer;
}

.footer-links p {
    color: var(--black);
    text-decoration: none;
    transition: all 0.3s ease;
}

.footer-links label {
    font-size: 1.2rem;
    font-weight: 300;
    color: var(--black);
    margin-bottom: 1.5rem;
}

.footer-links a.tel-link {
    font-size: 1.2rem;
    font-weight: 300 !important;
    color: var(--black) !important;
    transition: all 0.3s ease;
}

.footer-links a.tel-link:hover {
    color: var(--red) !important;
    cursor: pointer !important;
}

.footer-icon {
    width: auto;
    height: 1.3rem;
    margin-right: 0.8rem;
}
/*-- /Footer Links --*/

/*---- Tablet Devices ----*/
@media only screen and (max-width: 768px) {
    footer {
        padding: 2rem;
        margin: 0;
        background-color: var(--white);
        color: var(--gray);
        display: grid;
        grid-template-columns: 4fr 2fr 2fr 1fr;
        grid-template-rows: auto auto;
        gap: 0.1rem;
        justify-content: center;
        align-items: stretch;
        margin: auto;
    }
}
/*---- /Tablet Devices ----*/

/*---- Mobile Devices ----*/
@media only screen and (max-width: 576px) {
    footer {
        padding: 1rem;
        margin: 0;
        background-color: var(--white);
        color: var(--gray);
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto auto;
        justify-content: center;
        align-items: center;
        margin: auto;
    }

    .footer-brand-img {
        width: auto;
        height: 80px;
        /* Adjust to fit your logo */
        margin: auto;
        background-repeat: no-repeat;
    }

    footer div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.5rem;
    }

    footer div h3 {
        font-size: 2rem;
        margin-top: 0.7rem;
    }

    .footer-links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left;
    }

    .footer-links a,
    .footer-links div a {
        font-size: 1.2rem;
    }
}
/*---- /Mobile Devices ----*/