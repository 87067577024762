.bg-introduction-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: var(--lightred);
    background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('../assets/img/sarna-about.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 90vh;
    height: auto;
    width: auto;
    margin: auto;
    overflow-x: hidden;
}

.introduction-title {
    font-size: 5rem;
    margin-top: 0;
    margin-bottom: 0;
}

.btn-introduction-action {
    margin: 1rem 0 2rem 0;
    padding: 1rem;
    min-width: 30rem;
    max-width: 30rem;
    text-align: center;
    background-color: var(--red);
    color: var(--white);
    border-radius: 2rem;
    text-decoration: none;
}

.img-introduction-main {
    width: auto;
    height: 10rem;
    margin: 0.5rem 1.2rem 1.5rem 1.2rem;
}

.word-wrap-introduction-landing {
    word-wrap: break-word;
    width: 80vw;
}

.introduction-description {
    word-wrap: break-word;
    width: 40vw;
    font-size: 2rem;
}

@media only screen and (max-width: 768px) {
    .bg-introduction-main {
        position: relative;
        background-color: var(--lightred);
        background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('../assets/img/sarna-about.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 90vh;
        height: auto;
        width: auto;
        margin: auto;
        overflow-x: hidden;
    }
    .img-introduction-main {
        width: auto;
        height: 8rem;
        margin: 0.5rem 1.2rem 1.5rem 1.2rem;
    }

    .word-wrap-introduction-landing {
        word-wrap: break-word;
        width: 70vw;
    }

    .introduction-description {
        word-wrap: break-word;
        width: 60vw;
    }

    .btn-introduction-action {
        margin: 1rem 0 2rem 0;
        padding: 1rem;
        min-width: 30rem;
        max-width: 30rem;
        text-align: center;
        background-color: var(--red);
        color: var(--white);
        border-radius: 2rem;
        text-decoration: none;
    }
}

@media only screen and (max-width: 576px) {
    .bg-introduction-main {
        position: relative;
        background-color: var(--lightred);
        background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('../assets/img/sarna-about-small.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 90vh;
        height: auto;
        width: auto;
        margin: auto;
        overflow-x: hidden;
    }
    /* Images */
    .img-introduction-main {
        width: auto;
        height: 10rem;
        margin: 0.5rem 1.2rem 1.5rem 1.2rem;
    }

    /* /Images */
    .word-wrap-introduction-landing {
        word-wrap: break-word;
        width: 90vw;
    }

    .introduction-description {
        word-wrap: break-word;
        width: 80vw;
    }

    .btn-introduction-action {
        margin: 1rem auto 2rem auto;
        padding: 1rem;
        min-width: 70vw;
        max-width: 70vw;
        text-align: center;
        background-color: var(--red);
        color: var(--white);
        border-radius: 2rem;
        text-decoration: none;
    }
}