.get-started {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 40vh;
    max-height: 40vh;
    background-color: var(--lightred);
    background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('../assets/img/claudio-schwarz-purzlbaum-unsplash.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: var(--white);
}

.h1-get-started {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.btn-get-started-contact {
    margin: 1rem auto 2rem auto;
    padding: 1rem;
    background-color: var(--red);
    color: var(--white);
    border-radius: 2rem;
    text-decoration: none;
}