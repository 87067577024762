header {
    margin-bottom: 80px;
}

.nav {
    width: 100%;
    background-color: var(--white);
    border-bottom: 1px var(--whiteborder) solid;
    display: flex;
    justify-content: stretch;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 80px;
    z-index: 99;
    transition: top 0.2s ease-in;
}

.nav>.nav-header {
    display: inline;
}

.nav>.nav-header>.nav-title {
    flex-grow: 0;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 22px;
    color: #111;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
}

.navbar-brand-img {
    width: auto;
    height: 75px;
    /* Adjust to fit your logo */
    margin: 0.4rem 0.6rem 0 1.8rem;
    padding: 0;
    background-repeat: no-repeat;
}

/*-- Nav Items --*/
.nav>.nav-items {
    /*position*/
    flex-grow: 1;
    justify-content: flex-end;
    /*style*/
    display: flex;
    font-size: 18px;
    margin-right: 2.5rem;
}

.nav>.nav-items>a,
.nav>.nav-items>label {
    display: inline-block;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    color: var(--gray);
    font-weight: bold;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

.nav>.nav-items>a:focus,
.nav>.nav-items>label:focus {
    border: none;
    outline: none;
}

.nav > .nav-items > a.active,
.nav-items > label.active {
    color: var(--black);
    background-color: var(--lightwhite);
    border: 0.5px solid var(--grayborder);
    border-radius: 1.25rem;
}
.nav > .nav-items > a:hover,
.nav-items > label:hover {
    background-color: var(--lightwhite);
    color: var(--black);
    border-radius: 1.25rem;
}

/*-- /Nav Items --*/

/*-- Nav dropdown --*/
input#nav-dropdown {
    display: none;
}

input#nav-dropdown~label {
    position: relative;
    font-weight: bold;
    /* Signify it is a dropdown */
}

input#nav-dropdown + label:before {
    outline: none !important;
}

input#nav-dropdown~ul {
    position: absolute;
    top: 50px;
    /* Trial and error */
    right: 50px;
    /* Hard coded. Can be calc.ed with JS or done separately for each ul element. */
    padding: 0.5rem 1.5rem;
    background-color: #fff;
    border: 1px solid #ddd;
}

input#nav-dropdown~ul>li {
    display: block;
    list-style-type: none;
    background-color: #fff;
    padding: 0.5rem;
    text-align: center;
}

input#nav-dropdown~ul>li>a {
    color: #111;
    text-decoration: none;
}

input#nav-dropdown~ul>li:hover {
    background-color: #f2f2f2;
    border-radius: 0.3rem;
}

input#nav-dropdown:not(:checked)~ul {
    display: none;
}

input#nav-dropdown:checked~ul {
    display: block;
}

/*-- /Nav dropdown --*/

/*-- Responsive --*/
.nav>.nav-btn {
    display: none;
}

.nav>#nav-check {
    display: none;
}

@media only screen and (min-width: 993px) {
    .hyperlink-nav-hide {
        display: none !important;
    }
}

/*-- /Responsive --*/

/*----- Media Query for mobile and tablet devices -----*/
@media only screen and (max-width: 992px) {
    header {
        height: 85px;
        margin-bottom: 0;
    }
    .nav {
        position: static;
        height: 85px;
    }
    /* Width covers entire screen */

    /* /Width Covers Entire Screen */
    /* Navbar Brand */
    .navbar-brand-img {
        margin: 0.4rem 0.6rem 0 1.8rem;
        padding: 0.1rem;
        background-repeat: no-repeat;
    }

    /* /Navbar Brand */
    /* Navbar Toggle */
    .nav>.nav-btn {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
        height: 85px;
        padding-top: 28px;
        margin-right: 40px;
    }

    .nav>.nav-btn>label {
        display: inline-block;
        height: auto;
        font-size: 2rem;
        font-weight: bold;
        color: #111;
        margin-bottom: 0;
    }

    .nav>.nav-btn>label>span {
        display: block;
        border-top: 3px solid #111;
        height: 10px;
        width: 32px;
        transition: all 0.1s ease-in;
    }

    /* /Navbar Toggle */
    /* Navbar Items */
    .nav>.nav-items {
        position: absolute;
        display: block;
        width: 100%;
        background-color: #fff;
        text-align: center;
        transition: all 0.3s ease-in;
        top: 70px;
        /* navbar height */
        left: 0px;
        z-index: 999 !important;
    }

    .nav>.nav-items>a,
    .nav>.nav-items>label {
        display: block;
        width: 100%;
    }

    /* /Navbar Items */
    /* Toggle State */
    .nav>#nav-check:not(:checked)~.nav-items {
        height: 0px;
    }

    .nav>#nav-check:not(:checked)~.nav-items a,
    .nav>#nav-check:not(:checked)~.nav-items label {
        display: none;
    }

    .nav>#nav-check:checked~.nav-btn>label>span:first-child {
        transform: translateX(-3.5px) translateY(6px) rotate(45deg);
    }

    .nav>#nav-check:checked~.nav-btn>label>span:nth-child(2) {
        display: none;
    }

    .nav>#nav-check:checked~.nav-btn>label>span:last-child {
        transform: translateX(3px) translateY(-4px) rotate(-45deg);
    }

    .nav>#nav-check:checked~.nav-items {
        height: calc(100vh - 70px);
        overflow-y: auto;
        z-index: 999 !important;
    }

    .nav>#nav-check:checked~.nav-items a,
    .nav>#nav-check:checked~.nav-items label {
        display: block;
    }

    /* /Toggle State */
    /* Dropdown */
    input#nav-dropdown~ul {
        position: static;
        margin: auto;
        padding: 0.5rem 1.5rem;
        background-color: #fff;
        border: 1px solid #ddd;
    }
    /* /Dropdown */
}

@media only screen and (max-width: 576px) {
    .navbar-brand-img {
        margin: 0.4rem 0.6rem 0 0.6rem;
        padding: 0.1rem;
        background-repeat: no-repeat;
    }
    .nav>.nav-btn {
        margin-right: 30px;
    }
}