@import 'about.css';
@import 'accordion.css';
@import 'alert.css';
@import 'animation.css';
@import 'background.css';
@import 'buttons.css';
@import 'card.css';
@import 'color.css';
@import 'contact.css';
@import 'dropdown.css';
@import 'footer.css';
@import 'form.css';
@import 'getstarted.css';
@import 'home.css';
@import 'icon.css';
@import 'image.css';
@import 'introduction';
@import 'landing.css';
@import 'map.css';
@import 'modal.css';
@import 'navbar.css';
@import 'product.css';
@import 'scrollbar.css';
@import 'services.css';
@import 'spinner.css';
@import 'utils.css';

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

:root {
    --white: #fff;
    --whiteborder: #ddd;
    --lightwhite: #effbfe;
    --grayborder: #ededed;
    --lightgray: #aaa;
    --gray: #777;
    --black: #150605;
    --lightblue: #ace6ec;
    --lightred: #f47b6e;
    --red: #c26434;
}

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    margin: 0;
    overflow-x: hidden;
    background-color: var(--white);
    font-family: "Nunito", Helvetica, sans-serif;
}

.body-container {
    flex-grow: 1;
}

@media screen and (max-width: 768px) {
    .body-container {
        min-height: 100vh;
    }
    .content {
        display: flex;
        flex-direction: column;
        flex: 100%;
        background-color: var(--primary-color);
        color: var(--text-color);
    }
    .router-view {
        flex-grow: 1;
        background-color: var(--accent-color);
        padding: 1rem 0;
    }
    .container {
        padding: 3rem 2rem;
        margin: auto;
    }
}

@media only screen and (max-width: 576px) {
    .container {
        padding: 2rem 1rem;
        margin: auto;
    }
}