.icon {
    width: 1.5rem;
    height: 1.5rem;
    stroke: currentColor;
    stroke-width: 0.99px;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: currentColor;
    vertical-align: -0.15rem;
}

.icon-small {
    width: 1rem;
    height: 1rem;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    vertical-align: -0.15rem;
}
.icon-small-fill {
    fill: currentColor;
}

.fill-active {
    stroke: var(--active-color);
    fill: var(--active-color);
}
.fill-error {
    stroke: var(--error-color);
    fill: var(--error-color);
}

.icon-navbar {
    width: 2.2rem;
    height: 2.2rem;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1px;
    fill: currentColor;
    vertical-align: bottom;
}

.icon-sidebar-header {
    width: 1.75rem;
    height: 1.75rem;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0.8px;
    fill: currentColor;
    vertical-align: bottom;
}

.icon-sidebar {
    width: 1.1rem;
    height: 1.1rem;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0.7px;
    fill: none;
    vertical-align: bottom;
}

.icon-sidebar-arrow {
    margin-right: 1.5rem;
}

.icon-dashboard {
    width: 5rem;
    height: 5rem;
    stroke: var(--secondary-color);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0.98px;
    fill: none;
    vertical-align: -0.15rem;
    padding: 1rem;
}

.icon-select {
    position: absolute;
    top: 30%;
    right: 4%;
    width: 0.9rem;
    height: 0.9rem;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    vertical-align: -0.15rem;
}

.icon-accordion {
    position: absolute;
    top: 35%;
    right: 5%;
    width: 1rem;
    height: 1rem;
    stroke: var(--text-color);
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: var(--text-color);
    vertical-align: bottom;
}

.icon-action {
    width: 1.2rem;
    height: 1.2rem;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1px;
    fill: none;
    vertical-align: -0.15rem;
}

.icon-action-fill {
    fill: var(--secondary-color) !important;
    stroke-width: 0.6px !important;
}
.icon-action-fill:hover {
    fill: var(--active-color) !important;
}

.icon-stroke-thin {
    stroke-width: 0.5px !important;
}

.icon-comment {
    width: 1rem;
    height: 1rem;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 0.8px;
    fill: none;
    vertical-align: bottom;
}