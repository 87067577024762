.landing-title {
    font-size: 8rem;
    margin-bottom: 0;
}

.bg-landing {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--lightred);
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../assets/img/claudio-schwarz-purzlbaum-unsplash.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 93vh;
    height: auto;
    width: auto;
    overflow-x: hidden;
}

.bg-white {
    background-color: var(--white);
}

.btn-about {
    display: flex;
    flex-direction: row;
    margin: 1rem auto 2rem auto;
    padding: 1rem;
    background-color: var(--red);
    color: var(--white);
    border-radius: 2rem;
    text-decoration: none;
}

.img-main {
    width: auto;
    height: 10rem;
    margin: 0.5rem 1.2rem 1.5rem 1.2rem;
}

.word-wrap-landing {
    word-wrap: break-word;
    width: 60vw;
}

.arrow-icon {
    fill: white;
    width: 1rem;
    height: auto;
    display: inline;
    padding-left: 0.4rem;
    align-self: center;
}

@media only screen and (max-width: 768px) {
    .bg-landing {
        height: auto;
    }

    .img-main {
        width: auto;
        height: 8rem;
        margin: 0.5rem 1.2rem 1.5rem 1.2rem;
    }

    .word-wrap-landing {
        word-wrap: break-word;
        width: 60vw;
    }
}

@media only screen and (max-width: 576px) {
    .landing-title {
        font-size: 8rem;
        margin: auto;
        text-align: center;
        margin-bottom: 0;
    }

    /* Images */
    .img-main {
        width: auto;
        height: 10rem;
        margin: 0.5rem 1.2rem 1.5rem 1.2rem;
    }

    /* /Images */
    .word-wrap-landing {
        word-wrap: break-word;
        width: 80vw;
    }
}