.bg-main {
    position: relative;
    background-color: var(--lightred);
    background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('../assets/img/sarna-about-small.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 90vh;
    height: auto;
    width: auto;
    margin: auto;
    overflow-x: hidden;
}

@media only screen and (max-width: 768px) {
    .bg-main {
        position: relative;
        background-color: var(--lightred);
        background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('../assets/img/sarna-about-small.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 90vh;
        height: auto;
        width: auto;
        margin: auto;
        overflow-x: hidden;
    }
}

@media only screen and (max-width: 576px) {
    .bg-main {
        position: relative;
        background-color: var(--lightred);
        background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url('../assets/img/sarna-about-small.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 90vh;
        height: auto;
        width: auto;
        margin: auto;
        overflow-x: hidden;
    }
}