.h1-about {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.h2-about {
  padding-top: 2rem;
}

.h4-about {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-weight: normal;
}

.about-title {
  margin-bottom: 1.5rem;
  padding-left: 2.5rem;
  font-size: 3rem;
}

.btn-about-contact {
  background-color: var(--red);
  color: var(--white);
  border-radius: 2rem;
  margin: 1rem auto 2rem;
  padding: 1rem;
  text-decoration: none;
}

.word-wrap-about-description {
  word-wrap: break-word;
  width: 35vw;
  font-size: 1.5rem;
  font-weight: normal;
}

.word-wrap-about-value {
  word-wrap: break-word;
  width: 50vw;
  text-align: center;
  font-weight: normal;
}

@media only screen and (max-width: 768px) {
  .word-wrap-about-description {
    word-wrap: break-word;
    width: 50vw;
  }

  .word-wrap-about-value {
    word-wrap: break-word;
    width: 50vw;
    text-align: center;
    font-weight: normal;
  }
}

@media only screen and (max-width: 576px) {
  .word-wrap-about-description {
    word-wrap: break-word;
    width: 80vw;
  }

  .word-wrap-about-value {
    word-wrap: break-word;
    width: 80vw;
    text-align: center;
    font-weight: normal;
  }
}

.accordion-menu {
  background-color: var(--primary-color);
  padding: 1rem 0;
}

.accordion-menu .item {
  background-color: var(--primary-color);
  color: var(--text-color);
}

.accordion-menu .item.active {
  background-color: var(--hover-color);
}

.accordion-menu .item.active:hover, .accordion-menu .item:hover {
  background-color: var(--hover-color);
  cursor: pointer;
}

.btn-accordion {
  background-color: var(--accent-color);
  color: var(--text-color);
  cursor: pointer;
  width: 100%;
  height: auto;
  text-align: left;
  border: none;
  outline: none;
  font-size: 1.2rem;
  transition: all .4s;
  display: block;
  position: relative;
  padding: 1.3rem 0 1.3rem 2rem !important;
}

.btn-accordion:hover {
  background-color: var(--greyed-out-color);
}

.btn-accordion.active {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.accordion-content {
  background-color: var(--primary-color);
  max-height: 0;
  transition: max-height .2s ease-out;
  overflow: hidden;
}

#toasters {
  z-index: 2;
  flex-direction: column;
  row-gap: 10px;
  display: flex;
  position: fixed;
  top: 10vh;
  right: 30px;
}

#toaster {
  visibility: hidden;
  min-width: 10vw;
  max-width: 40vw;
  text-align: left;
  background-color: var(--primary-color);
  border-radius: 2px;
  margin-top: 18px;
  margin-left: -125px;
  padding: 18px 36px;
  font-size: 1.2rem;
}

#toaster.show {
  visibility: visible;
  animation: .5s toastfadein;
}

.toaster-border {
  border: 2px solid #f0f0f0;
}

.toaster-btn-close {
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  outline: none;
  font-size: 1.8rem;
  text-decoration: none;
}

.toaster-btn-close:hover {
  color: #ff4d00;
  cursor: pointer;
  text-decoration: none;
}

@media screen and (max-width: 992px) {
  #toaster {
    visibility: hidden;
    max-width: 99vw;
    text-align: left;
    word-wrap: break-word;
    word-wrap: anywhere;
    z-index: 3;
    background-color: #fff;
    border-radius: 2px;
    margin: auto;
    padding: 16px 10vw;
    font-size: 17px;
  }

  #toaster.show {
    visibility: visible;
    animation: .5s toastfadeinmobile;
  }
}

.fade-in {
  animation: 1s fadeIn;
}

.sidebar-slide {
  animation: .6s sidebarSlide;
}

@media screen and (max-width: 768px) {
  .navbar-slide {
    animation: .4s navbarSlide;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes toastfadein {
  from {
    opacity: 0;
    right: 0;
  }

  to {
    opacity: 1;
    right: 30px;
  }
}

@keyframes toastfadeinmobile {
  from {
    opacity: 0;
    top: 0;
  }

  to {
    opacity: 1;
    top: 10vh;
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes navbarSlide {
  0% {
    margin-top: -70px;
  }

  100% {
    margin-top: 0;
  }
}

@keyframes sidebarSlide {
  0% {
    margin-left: -40%;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.bg-main {
  background-color: var(--lightred);
  min-height: 90vh;
  height: auto;
  width: auto;
  background-color: #0000;
  background-image: linear-gradient(#0003, #0003), url("sarna-about-small.078cd9af.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  margin: auto;
  position: relative;
  overflow-x: hidden;
}

@media only screen and (max-width: 768px) {
  .bg-main {
    background-color: var(--lightred);
    min-height: 90vh;
    height: auto;
    width: auto;
    background-color: #0000;
    background-image: linear-gradient(#0003, #0003), url("sarna-about-small.078cd9af.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    margin: auto;
    position: relative;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 576px) {
  .bg-main {
    background-color: var(--lightred);
    min-height: 90vh;
    height: auto;
    width: auto;
    background-color: #0000;
    background-image: linear-gradient(#0003, #0003), url("sarna-about-small.078cd9af.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    margin: auto;
    position: relative;
    overflow-x: hidden;
  }
}

.hyperlink {
  background-color: var(--element-color);
  color: var(--accent-color);
  border: 1px solid var(--element-color);
  padding: 1.3rem;
  font-size: 1.5rem;
  font-weight: 700 !important;
}

.hyperlink:hover {
  background-color: var(--hover-color);
  border-color: var(--hover-color);
  cursor: pointer;
}

.hyperlink.active {
  background-color: var(--active-color);
  border: 1px solid var(--active-color);
  color: var(--element-color);
  font-weight: bold;
}

.hyperlink.active:hover {
  background-color: var(--hover-color);
  border-color: var(--hover-color);
  cursor: pointer;
}

.btn-form {
  width: 100%;
  margin: 1rem auto 0;
  padding: .6rem;
  font-size: 1.3rem;
}

.btn-form-border {
  border-radius: 1rem;
}

.btn-submit {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: 1px solid var(--secondary-color);
}

.btn-submit:hover {
  background-color: var(--active-color);
  border: 1px solid var(--active-color);
  cursor: pointer;
}

.btn-submit:disabled {
  opacity: .6;
}

.btn-submit:disabled:hover {
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  cursor: progress;
}

.btn-cancel {
  background-color: var(--error-color);
  color: var(--primary-color);
  border: 1px solid var(--error-color);
}

.btn-cancel:hover {
  background-color: var(--active-color);
  color: var(--primary-color);
  border: 1px solid var(--active-color);
  cursor: pointer;
}

.btn-action {
  border-radius: .5rem;
  padding: .45rem 1.5rem .45rem 1rem;
  font-size: 1.3rem;
}

.btn-action:hover {
  cursor: pointer;
}

.btn-action.disabled, .btn-action.disabled:hover {
  opacity: .6 !important;
  cursor: progress !important;
}

.btn-action-primary {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.btn-action-primary.active, .btn-action-primary:hover {
  background-color: var(--active-color);
  border: 1px solid var(--active-color);
  color: var(--primary-color);
}

.btn-action-primary.active svg, .btn-action-primary:hover svg {
  fill: var(--active-color) !important;
}

.btn-action-secondary {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border: 1px solid var(--secondary-color);
}

.btn-action-secondary.active, .btn-action-secondary:hover {
  background-color: var(--active-color);
  border: 1px solid var(--active-color);
  color: var(--primary-color);
}

.btn-action-secondary.active svg, .btn-action-secondary:hover svg {
  fill: var(--active-color) !important;
}

.btn-comment {
  margin: .6rem 0;
  padding: .7rem .8rem;
  font-size: 1.3rem;
}

.btn-comment-border {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.card {
  border-radius: 5px;
  flex-direction: column;
  display: flex;
  box-shadow: 0 4px 8px #0003;
}

.card:hover {
  box-shadow: 0 6px 10px #0003;
}

.card-small-shadow {
  box-shadow: 0 2px 2px #0003 !important;
}

.card-primary {
  background-color: var(--primary-color);
  color: var(--text-color);
}

.card-login {
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  margin: 10vh 3rem;
  display: flex;
}

.card-login-logo {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  flex-grow: 1;
}

.card-login-title {
  text-align: center;
  padding-bottom: 1.5rem;
}

.card-login-form {
  background-color: var(--primary-color);
  color: var(--text-color);
  flex-grow: 4;
}

.card-login-logo, .card-login-form {
  padding: 7rem 5rem;
}

.card-animation {
  transition: all .3s;
  animation: .5s ease-in-out fadeIn;
}

@media screen and (max-width: 1024px) {
  .card-login {
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    display: flex;
    margin: 10vh .5rem !important;
  }

  .card-login-logo {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    flex-grow: 1;
  }

  .card-login-form {
    background-color: var(--primary-color);
    color: var(--text-color);
    flex-grow: 3;
  }

  .card-login-logo, .card-login-form {
    padding: 2rem 1rem;
  }

  .card-login-form * .form-row {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .card-login {
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    display: flex;
    margin: 5vh 1rem 1vh !important;
  }

  .card-login-logo {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    flex-grow: 1;
  }

  .card-login-form {
    background-color: var(--primary-color);
    color: var(--text-color);
    flex-grow: 2;
  }

  .card-login-logo, .card-login-form {
    padding: 3rem 1rem;
  }

  .card-login-form * .form-row {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.bg-white {
  background-color: var(--white);
}

.bg-lightwhite {
  background-color: var(--lightwhite);
}

.text-white {
  color: var(--white);
}

.text-lightwhite {
  color: var(--lightwhite);
}

.text-gray {
  color: var(--gray);
}

.text-black {
  color: var(--black);
}

.text-red {
  color: var(--red);
}

.contact-page {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1.5fr;
  display: grid;
}

.bg-contact {
  width: auto;
  color: var(--white);
  background-color: #3f3d56;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow-x: hidden;
}

.bg-contact-md {
  border-left: .1px solid var(--whiteborder);
  flex-direction: column;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
}

.contact-container {
  margin: auto;
  padding: 3rem 5rem;
}

.contact-container h1, .contact-container h4 {
  padding-bottom: 1rem;
}

.company-information {
  text-align: left;
  grid-template-rows: auto auto auto auto;
  grid-template-columns: auto auto;
  justify-content: start;
  align-items: center;
  gap: 3rem;
  padding: 3rem 1rem 0;
  display: grid;
}

.contact-link {
  color: var(--white);
  text-decoration: none;
}

.contact-link:hover {
  color: var(--whiteborder);
  text-decoration: none;
}

.contact-icon {
  fill: var(--red);
  height: 3rem;
  width: auto;
}

.subscribe-container {
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}

.svg-subscribe {
  width: auto;
  height: 11rem;
  padding-top: 1rem;
  padding-bottom: .5rem;
}

@media only screen and (max-width: 768px) {
  .contact-page {
    background-color: var(--lightred);
    background: url("undraw_contact_us.87e5110a.svg") center / cover no-repeat;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    padding-bottom: 2rem;
    display: grid;
    position: relative;
    overflow-x: hidden;
  }

  .bg-contact {
    height: auto;
    width: auto;
    color: var(--white);
    background-color: #3f3d56;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow-x: hidden;
  }

  .bg-contact-md {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
  }

  .contact-icon {
    fill: var(--red);
    height: 2rem;
    width: auto;
  }

  .company-information {
    text-align: left;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    display: grid;
  }

  .svg-subscribe {
    width: 50%;
    height: 0;
    position: absolute;
    bottom: 1%;
    left: 15%;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 576px) {
  .contact-container {
    margin: auto;
    padding: 1rem;
  }
}

.dropdown {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.dropdown:hover {
  background-color: var(--accent-color);
  cursor: pointer;
}

.dropdown-menu {
  width: 100%;
  text-align: center;
}

.dropdown-content {
  visibility: hidden;
  opacity: 0;
  background-color: var(--primary-color);
  color: var(--text-color);
  width: 100%;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: visibility, opacity .2s linear;
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  box-shadow: 0 2px 2px #0003;
}

.dropdown-content.show {
  visibility: visible;
  opacity: 1;
}

.dropdown-content.show a {
  width: 100%;
  background-color: var(--primary-color);
  color: var(--text-color);
  border-bottom: 1px solid var(--accent-color);
  text-align: center;
  padding: .8rem 0;
  font-size: 1.1rem;
}

.dropdown-content.show a:hover, .dropdown-content.show a:focus {
  background-color: var(--active-color);
  color: var(--primary-color);
}

.dropdown-content.show a:hover {
  cursor: pointer;
}

.dropdown-content.show a:focus {
  cursor: auto;
}

.user-dropdown {
  min-width: 10rem;
}

@media screen and (max-width: 576px) {
  .user-dropdown {
    min-width: 8rem;
  }
}

footer {
  border-top: 1px var(--whiteborder) solid;
  border-bottom: 1px var(--whiteborder) solid;
  background-color: var(--white);
  color: var(--gray);
  grid-template-rows: 1fr auto;
  grid-template-columns: 1.2fr 1fr 1fr 1.2fr;
  padding: 1.5rem 3rem;
  display: grid;
  margin: 0 !important;
}

.footer-brand-img {
  width: auto;
  height: 100px;
  background-repeat: no-repeat;
  margin: -.2rem auto auto 2.5rem;
}

footer div h3 {
  font-size: 1.6rem;
}

.footer-links {
  text-align: left;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
  display: flex;
}

.footer-links a, .footer-links div a {
  color: var(--black);
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  text-decoration: none;
  transition: all .3s;
}

.footer-links a:hover, .footer-links div a:hover {
  color: var(--red);
  cursor: pointer;
  text-decoration: none;
}

.footer-links p {
  color: var(--black);
  text-decoration: none;
  transition: all .3s;
}

.footer-links label {
  color: var(--black);
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  font-weight: 300;
}

.footer-links a.tel-link {
  font-size: 1.2rem;
  transition: all .3s;
  color: var(--black) !important;
  font-weight: 300 !important;
}

.footer-links a.tel-link:hover {
  color: var(--red) !important;
  cursor: pointer !important;
}

.footer-icon {
  width: auto;
  height: 1.3rem;
  margin-right: .8rem;
}

@media only screen and (max-width: 768px) {
  footer {
    background-color: var(--white);
    color: var(--gray);
    grid-template-rows: auto auto;
    grid-template-columns: 4fr 2fr 2fr 1fr;
    justify-content: center;
    align-items: stretch;
    gap: .1rem;
    margin: auto;
    padding: 2rem;
    display: grid;
  }
}

@media only screen and (max-width: 576px) {
  footer {
    background-color: var(--white);
    color: var(--gray);
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 100%;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 1rem;
    display: grid;
  }

  .footer-brand-img {
    width: auto;
    height: 80px;
    background-repeat: no-repeat;
    margin: auto;
  }

  footer div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: .5rem;
    display: flex;
  }

  footer div h3 {
    margin-top: .7rem;
    font-size: 2rem;
  }

  .footer-links {
    text-align: left;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .footer-links a, .footer-links div a {
    font-size: 1.2rem;
  }
}

form {
  padding: 1rem;
}

label {
  margin-bottom: 5px;
  display: block;
}

input, select {
  width: 100%;
  background-color: var(--grayborder);
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 20px;
  outline: 0;
  margin-bottom: 20px;
  padding: 10px 15px;
  font-size: 14px;
}

.textarea-div {
  width: 100%;
}

textarea {
  width: 99%;
  background-color: var(--grayborder);
  height: 100px;
  border: 0;
  border-radius: 20px;
  outline: 0;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 14px;
}

.submit-section {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.btn {
  background-color: var(--red);
  color: var(--white);
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #0000;
  border-radius: 20px;
  margin: auto;
  padding: 12px 15px;
  font-size: 16px;
  font-weight: 200;
  transition: all .3s;
}

.btn:hover {
  cursor: pointer;
  background-color: var(--lightred);
}

.btn:focus {
  outline: none;
}

.send-icon {
  fill: #fff;
  width: 1rem;
  height: auto;
  padding-left: .2rem;
  display: inline;
}

.check-icon {
  fill: #fff;
  width: 1.2rem;
  height: auto;
  display: inline;
}

@media screen and (min-width: 768px) {
  .large-group {
    flex-wrap: wrap;
    justify-content: space-between;
    display: flex;
  }

  .small-group {
    width: calc(100% - 1rem);
  }
}

@media screen and (max-width: 768px) {
  .contact-form {
    background-color: var(--white);
    border: 1px solid var(--whiteborder);
    border-radius: 1rem;
    padding: 2rem;
  }

  input, select {
    width: calc(100% - 1rem);
  }
}

.get-started {
  min-height: 40vh;
  max-height: 40vh;
  background-color: var(--lightred);
  color: var(--white);
  background-color: #0000;
  background-image: linear-gradient(#0006, #0006), url("claudio-schwarz-purzlbaum-unsplash.5853e933.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.h1-get-started {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.btn-get-started-contact {
  background-color: var(--red);
  color: var(--white);
  border-radius: 2rem;
  margin: 1rem auto 2rem;
  padding: 1rem;
  text-decoration: none;
}

.home-products {
  background-color: var(--lightwhite);
}

.home-products-list {
  min-width: 80vw;
  max-width: 80vw;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: grid;
}

.home-products-intro {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.home-product-info {
  flex-direction: column;
  display: flex;
}

.btn-view-home-products {
  background-color: var(--red);
  color: var(--white);
  border-radius: 2rem;
  margin: 1rem auto 2rem;
  padding: 1rem;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .home-products-list {
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: grid;
  }
}

@media only screen and (max-width: 576px) {
  .home-products-list {
    grid-template-rows: auto auto auto auto auto auto;
    grid-template-columns: 100%;
    gap: 2rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: grid;
  }
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  stroke: currentColor;
  stroke-width: .99px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: currentColor;
  vertical-align: -.15rem;
}

.icon-small {
  width: 1rem;
  height: 1rem;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  vertical-align: -.15rem;
}

.icon-small-fill {
  fill: currentColor;
}

.fill-active {
  stroke: var(--active-color);
  fill: var(--active-color);
}

.fill-error {
  stroke: var(--error-color);
  fill: var(--error-color);
}

.icon-navbar {
  width: 2.2rem;
  height: 2.2rem;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1px;
  fill: currentColor;
  vertical-align: bottom;
}

.icon-sidebar-header {
  width: 1.75rem;
  height: 1.75rem;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: .8px;
  fill: currentColor;
  vertical-align: bottom;
}

.icon-sidebar {
  width: 1.1rem;
  height: 1.1rem;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: .7px;
  fill: none;
  vertical-align: bottom;
}

.icon-sidebar-arrow {
  margin-right: 1.5rem;
}

.icon-dashboard {
  width: 5rem;
  height: 5rem;
  stroke: var(--secondary-color);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: .98px;
  fill: none;
  vertical-align: -.15rem;
  padding: 1rem;
}

.icon-select {
  width: .9rem;
  height: .9rem;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  vertical-align: -.15rem;
  position: absolute;
  top: 30%;
  right: 4%;
}

.icon-accordion {
  width: 1rem;
  height: 1rem;
  stroke: var(--text-color);
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: var(--text-color);
  vertical-align: bottom;
  position: absolute;
  top: 35%;
  right: 5%;
}

.icon-action {
  width: 1.2rem;
  height: 1.2rem;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1px;
  fill: none;
  vertical-align: -.15rem;
}

.icon-action-fill {
  fill: var(--secondary-color) !important;
  stroke-width: .6px !important;
}

.icon-action-fill:hover {
  fill: var(--active-color) !important;
}

.icon-stroke-thin {
  stroke-width: .5px !important;
}

.icon-comment {
  width: 1rem;
  height: 1rem;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: .8px;
  fill: none;
  vertical-align: bottom;
}

.img-login {
  width: auto;
  height: 10rem;
  margin: auto;
  padding: 2rem;
  display: block;
}

.img-sidebar {
  width: auto;
  height: 6rem;
  padding: .7rem;
  display: block;
}

@media screen and (max-width: 768px) {
  .img-login {
    width: 80vw;
    height: auto;
    margin: auto;
    padding: 2rem;
    display: block;
  }
}

.bg-introduction-main {
  background-color: var(--lightred);
  min-height: 90vh;
  height: auto;
  width: auto;
  background-color: #0000;
  background-image: linear-gradient(#0003, #0003), url("sarna-about.0000e5c3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.introduction-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 5rem;
}

.btn-introduction-action {
  min-width: 30rem;
  max-width: 30rem;
  text-align: center;
  background-color: var(--red);
  color: var(--white);
  border-radius: 2rem;
  margin: 1rem 0 2rem;
  padding: 1rem;
  text-decoration: none;
}

.img-introduction-main {
  width: auto;
  height: 10rem;
  margin: .5rem 1.2rem 1.5rem;
}

.word-wrap-introduction-landing {
  word-wrap: break-word;
  width: 80vw;
}

.introduction-description {
  word-wrap: break-word;
  width: 40vw;
  font-size: 2rem;
}

@media only screen and (max-width: 768px) {
  .bg-introduction-main {
    background-color: var(--lightred);
    min-height: 90vh;
    height: auto;
    width: auto;
    background-color: #0000;
    background-image: linear-gradient(#0003, #0003), url("sarna-about.0000e5c3.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    margin: auto;
    position: relative;
    overflow-x: hidden;
  }

  .img-introduction-main {
    width: auto;
    height: 8rem;
    margin: .5rem 1.2rem 1.5rem;
  }

  .word-wrap-introduction-landing {
    word-wrap: break-word;
    width: 70vw;
  }

  .introduction-description {
    word-wrap: break-word;
    width: 60vw;
  }

  .btn-introduction-action {
    min-width: 30rem;
    max-width: 30rem;
    text-align: center;
    background-color: var(--red);
    color: var(--white);
    border-radius: 2rem;
    margin: 1rem 0 2rem;
    padding: 1rem;
    text-decoration: none;
  }
}

@media only screen and (max-width: 576px) {
  .bg-introduction-main {
    background-color: var(--lightred);
    min-height: 90vh;
    height: auto;
    width: auto;
    background-color: #0000;
    background-image: linear-gradient(#0003, #0003), url("sarna-about-small.078cd9af.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    margin: auto;
    position: relative;
    overflow-x: hidden;
  }

  .img-introduction-main {
    width: auto;
    height: 10rem;
    margin: .5rem 1.2rem 1.5rem;
  }

  .word-wrap-introduction-landing {
    word-wrap: break-word;
    width: 90vw;
  }

  .introduction-description {
    word-wrap: break-word;
    width: 80vw;
  }

  .btn-introduction-action {
    min-width: 70vw;
    max-width: 70vw;
    text-align: center;
    background-color: var(--red);
    color: var(--white);
    border-radius: 2rem;
    margin: 1rem auto 2rem;
    padding: 1rem;
    text-decoration: none;
  }
}

.landing-title {
  margin-bottom: 0;
  font-size: 8rem;
}

.bg-landing {
  background-color: var(--lightred);
  min-height: 93vh;
  height: auto;
  width: auto;
  background-color: #0000;
  background-image: linear-gradient(#0006, #0006), url("claudio-schwarz-purzlbaum-unsplash.5853e933.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.bg-white {
  background-color: var(--white);
}

.btn-about {
  background-color: var(--red);
  color: var(--white);
  border-radius: 2rem;
  flex-direction: row;
  margin: 1rem auto 2rem;
  padding: 1rem;
  text-decoration: none;
  display: flex;
}

.img-main {
  width: auto;
  height: 10rem;
  margin: .5rem 1.2rem 1.5rem;
}

.word-wrap-landing {
  word-wrap: break-word;
  width: 60vw;
}

.arrow-icon {
  fill: #fff;
  width: 1rem;
  height: auto;
  align-self: center;
  padding-left: .4rem;
  display: inline;
}

@media only screen and (max-width: 768px) {
  .bg-landing {
    height: auto;
  }

  .img-main {
    width: auto;
    height: 8rem;
    margin: .5rem 1.2rem 1.5rem;
  }

  .word-wrap-landing {
    word-wrap: break-word;
    width: 60vw;
  }
}

@media only screen and (max-width: 576px) {
  .landing-title {
    text-align: center;
    margin: auto auto 0;
    font-size: 8rem;
  }

  .img-main {
    width: auto;
    height: 10rem;
    margin: .5rem 1.2rem 1.5rem;
  }

  .word-wrap-landing {
    word-wrap: break-word;
    width: 80vw;
  }
}

.map-header {
  width: 90vw;
  margin: 1rem auto;
}

.map-container {
  border-top: 1px solid var(--whiteborder);
  border-bottom: 1px solid var(--whiteborder);
  width: 90vw;
  height: 400px;
  margin: 0 auto 2rem;
}

@media only screen and (max-width: 768px) {
  .map-container {
    border-top: 1px solid var(--whiteborder);
    border-bottom: 1px solid var(--whiteborder);
    width: 90vw;
    height: 500px;
    margin: 0 auto 2rem;
  }
}

.app-modal {
  display: none;
}

.ui-modal {
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #0009;
  padding-top: 10vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.ui-modal-title-wrapper {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem 1rem;
  display: flex;
}

.ui-modal-content {
  width: 90%;
  max-width: 70vw;
  margin: auto;
  display: block;
  position: relative;
}

.ui-modal-caption {
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: var(--hover-color);
  margin: auto;
  padding: 2rem 0;
  display: block;
}

.ui-modal-content, #caption {
  animation-name: zoom;
  animation-duration: .3s;
}

.ui-modal-close {
  color: var(--text-color);
  z-index: 10;
  font-size: 2rem;
  font-weight: bold;
  transition: all .3s;
}

.ui-modal-close:hover, .ui-modal-close:focus {
  color: var(--error-color);
  cursor: pointer;
  text-decoration: none;
}

@media screen and (max-width: 576px) {
  .ui-modal-title-wrapper {
    flex-direction: column;
  }
}

header {
  margin-bottom: 80px;
}

.nav {
  width: 100%;
  background-color: var(--white);
  border-bottom: 1px var(--whiteborder) solid;
  height: 80px;
  z-index: 99;
  justify-content: stretch;
  align-items: center;
  transition: top .2s ease-in;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.nav > .nav-header {
  display: inline;
}

.nav > .nav-header > .nav-title {
  color: #111;
  cursor: pointer;
  flex-grow: 0;
  align-items: center;
  gap: 1rem;
  padding: 10px;
  font-size: 22px;
  display: flex;
}

.navbar-brand-img {
  width: auto;
  height: 75px;
  background-repeat: no-repeat;
  margin: .4rem .6rem 0 1.8rem;
  padding: 0;
}

.nav > .nav-items {
  flex-grow: 1;
  justify-content: flex-end;
  margin-right: 2.5rem;
  font-size: 18px;
  display: flex;
}

.nav > .nav-items > a, .nav > .nav-items > label {
  color: var(--gray);
  cursor: pointer;
  padding: 10px 20px;
  font-weight: bold;
  text-decoration: none;
  transition: all .2s ease-in;
  display: inline-block;
}

.nav > .nav-items > a:focus, .nav > .nav-items > label:focus {
  border: none;
  outline: none;
}

.nav > .nav-items > a.active, .nav-items > label.active {
  color: var(--black);
  background-color: var(--lightwhite);
  border: .5px solid var(--grayborder);
  border-radius: 1.25rem;
}

.nav > .nav-items > a:hover, .nav-items > label:hover {
  background-color: var(--lightwhite);
  color: var(--black);
  border-radius: 1.25rem;
}

input#nav-dropdown {
  display: none;
}

input#nav-dropdown ~ label {
  font-weight: bold;
  position: relative;
}

input#nav-dropdown + label:before {
  outline: none !important;
}

input#nav-dropdown ~ ul {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: .5rem 1.5rem;
  position: absolute;
  top: 50px;
  right: 50px;
}

input#nav-dropdown ~ ul > li {
  text-align: center;
  background-color: #fff;
  padding: .5rem;
  list-style-type: none;
  display: block;
}

input#nav-dropdown ~ ul > li > a {
  color: #111;
  text-decoration: none;
}

input#nav-dropdown ~ ul > li:hover {
  background-color: #f2f2f2;
  border-radius: .3rem;
}

input#nav-dropdown:not(:checked) ~ ul {
  display: none;
}

input#nav-dropdown:checked ~ ul {
  display: block;
}

.nav > .nav-btn, .nav > #nav-check {
  display: none;
}

@media only screen and (min-width: 993px) {
  .hyperlink-nav-hide {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {
  header {
    height: 85px;
    margin-bottom: 0;
  }

  .nav {
    height: 85px;
    position: static;
  }

  .navbar-brand-img {
    background-repeat: no-repeat;
    margin: .4rem .6rem 0 1.8rem;
    padding: .1rem;
  }

  .nav > .nav-btn {
    height: 85px;
    flex-grow: 1;
    justify-content: flex-end;
    margin-right: 40px;
    padding-top: 28px;
    display: flex;
  }

  .nav > .nav-btn > label {
    height: auto;
    color: #111;
    margin-bottom: 0;
    font-size: 2rem;
    font-weight: bold;
    display: inline-block;
  }

  .nav > .nav-btn > label > span {
    height: 10px;
    width: 32px;
    border-top: 3px solid #111;
    transition: all .1s ease-in;
    display: block;
  }

  .nav > .nav-items {
    width: 100%;
    text-align: center;
    background-color: #fff;
    transition: all .3s ease-in;
    display: block;
    position: absolute;
    top: 70px;
    left: 0;
    z-index: 999 !important;
  }

  .nav > .nav-items > a, .nav > .nav-items > label {
    width: 100%;
    display: block;
  }

  .nav > #nav-check:not(:checked) ~ .nav-items {
    height: 0;
  }

  .nav > #nav-check:not(:checked) ~ .nav-items a, .nav > #nav-check:not(:checked) ~ .nav-items label {
    display: none;
  }

  .nav > #nav-check:checked ~ .nav-btn > label > span:first-child {
    transform: translateX(-3.5px)translateY(6px)rotate(45deg);
  }

  .nav > #nav-check:checked ~ .nav-btn > label > span:nth-child(2) {
    display: none;
  }

  .nav > #nav-check:checked ~ .nav-btn > label > span:last-child {
    transform: translateX(3px)translateY(-4px)rotate(-45deg);
  }

  .nav > #nav-check:checked ~ .nav-items {
    height: calc(100vh - 70px);
    overflow-y: auto;
    z-index: 999 !important;
  }

  .nav > #nav-check:checked ~ .nav-items a, .nav > #nav-check:checked ~ .nav-items label {
    display: block;
  }

  input#nav-dropdown ~ ul {
    background-color: #fff;
    border: 1px solid #ddd;
    margin: auto;
    padding: .5rem 1.5rem;
    position: static;
  }
}

@media only screen and (max-width: 576px) {
  .navbar-brand-img {
    background-repeat: no-repeat;
    margin: .4rem .6rem 0;
    padding: .1rem;
  }

  .nav > .nav-btn {
    margin-right: 30px;
  }
}

.bg-product-intro-main {
  background-color: var(--lightred);
  min-height: 90vh;
  height: auto;
  width: auto;
  background-color: #0000;
  background-image: linear-gradient(#0003, #0003), url("product-landing.aa155039.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.img-product-intro-main {
  width: auto;
  height: 10rem;
  margin: .5rem 1.2rem 1.5rem;
}

.word-wrap-product-intro-landing {
  word-wrap: break-word;
  width: 80vw;
}

.product-intro-description {
  word-wrap: break-word;
  width: 40vw;
  font-size: 2rem;
}

.btn-product-action {
  min-width: 30rem;
  max-width: 30rem;
  text-align: center;
  background-color: var(--red);
  color: var(--white);
  border-radius: 2rem;
  margin: 1rem 0 2rem;
  padding: .5rem;
  text-decoration: none;
}

.products {
  background-color: var(--lightwhite);
}

.products-title, .product-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 5rem;
}

.product-info {
  flex-direction: column;
  display: flex;
}

.product-card {
  background-color: var(--white);
  border: 1px solid var(--lightblue);
  border-radius: 1rem;
  padding: .5rem .5rem 1rem 1.5rem;
}

.product-card:hover {
  box-shadow: 0 2px 2px #0003;
}

.product-card-img {
  text-align: center;
  width: auto;
  max-width: 90%;
  height: 11rem;
  margin: auto;
}

.products-intro {
  min-height: 80vh;
  background-color: var(--lightred);
  color: var(--white);
  background-color: #0000;
  background-image: linear-gradient(#0000001a, #0000001a), url("product-landing.aa155039.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.products-list {
  min-width: 80vw;
  max-width: 80vw;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: grid;
}

.products-product-intro-description {
  word-wrap: break-word;
  width: 80vw;
  margin-bottom: 1rem;
  font-weight: normal;
}

.contact-section {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem auto auto;
  display: flex;
}

.btn-contact {
  background-color: var(--red);
  color: var(--white);
  border-radius: 2rem;
  margin: 1rem auto;
  padding: 1rem 1.5rem;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .bg-product-intro-main {
    background-color: var(--lightred);
    min-height: 90vh;
    height: auto;
    width: auto;
    background-color: #0000;
    background-image: linear-gradient(#0003, #0003), url("product-landing-medium.76338722.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    margin: auto;
    position: relative;
    overflow-x: hidden;
  }

  .img-product-intro-main {
    width: auto;
    height: 8rem;
    margin: .5rem 1.2rem 1.5rem;
  }

  .word-wrap-product-intro-landing {
    word-wrap: break-word;
    width: 70vw;
  }

  .product-intro-description {
    word-wrap: break-word;
    width: 60vw;
  }

  .btn-product-action {
    min-width: 30rem;
    max-width: 30rem;
    text-align: center;
    background-color: var(--red);
    color: var(--white);
    border-radius: 2rem;
    margin: 1rem 0 2rem;
    padding: .5rem;
    text-decoration: none;
  }

  .products-list {
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: grid;
  }

  .products-product-intro-description {
    word-wrap: break-word;
    width: 60vw;
    margin-bottom: 1rem;
    font-weight: normal;
  }
}

@media screen and (max-width: 576px) {
  .bg-product-intro-main {
    background-color: var(--lightred);
    min-height: 90vh;
    height: auto;
    width: auto;
    background-color: #0000;
    background-image: linear-gradient(#0003, #0003), url("product-landing-small.509a0a31.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    margin: auto;
    position: relative;
    overflow-x: hidden;
  }

  .img-product-intro-main {
    width: auto;
    height: 10rem;
    margin: .5rem 1.2rem 1.5rem;
  }

  .word-wrap-product-intro-landing {
    word-wrap: break-word;
    width: 90vw;
  }

  .product-intro-description {
    word-wrap: break-word;
    width: 80vw;
  }

  .btn-product-action {
    min-width: 70vw;
    max-width: 70vw;
    text-align: center;
    background-color: var(--red);
    color: var(--white);
    border-radius: 2rem;
    margin: 1rem auto 2rem;
    padding: .5rem;
    text-decoration: none;
  }

  .product-card-img {
    text-align: center;
    width: auto;
    max-width: 90%;
    height: 11rem;
    margin: 1rem auto 2rem;
    padding: 0 !important;
  }

  .products-intro {
    min-height: 80vh;
    background-color: var(--lightred);
    color: var(--white);
    background-color: #0000;
    background-image: linear-gradient(#0000001a, #0000001a), url("product-landing-small.509a0a31.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll, scroll;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
  }

  .products-title {
    text-align: center;
    margin: 0 auto;
    font-size: 5rem;
  }

  .products-list {
    grid-template-rows: auto auto auto auto auto auto;
    grid-template-columns: 100%;
    gap: 2rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: grid;
  }

  .products-product-intro-description {
    word-wrap: break-word;
    width: 90vw;
    margin: auto auto 1rem;
    font-weight: normal;
  }
}

:root {
  scrollbar-color: #ccc var(--red);
  scrollbar-gutter: auto;
  scrollbar-width: auto;
}

::-webkit-scrollbar {
  width: .7rem;
}

::-webkit-scrollbar-track {
  background-color: var(--white);
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--red);
}

.services {
  min-width: 80vw;
  max-width: 80vw;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: grid;
}

.img-services-main {
  width: 8rem;
  height: auto;
  margin: .5rem 1.2rem 1.5rem;
}

@media only screen and (max-width: 768px) {
  .img-services-main {
    width: 6rem;
    height: auto;
    margin: .5rem 1.2rem 1.5rem;
  }
}

@media only screen and (max-width: 576px) {
  .services {
    min-width: 95vw;
    max-width: 95vw;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    display: grid;
  }

  .img-services-main {
    width: 7rem;
    height: auto;
    margin: .5rem 1.2rem 1.5rem;
  }
}

.spinner {
  width: 1.2rem;
  height: 1.2rem;
  background-color: #0000;
  border-radius: 50%;
  margin: 0 auto;
}

.rotate {
  border-top: 2px solid #fff;
  border-right: 2px solid #0000;
  animation: .8s linear infinite rotate;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
}

a {
  text-decoration: none;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-grow-4 {
  flex-grow: 4;
}

.flex-gap-1 {
  gap: 1rem;
}

.flex-gap-2 {
  gap: 2rem;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-stretch {
  justify-content: stretch !important;
}

.justify-content-end {
  justify-content: end;
}

.align-items-center, .align-items-stretch {
  align-items: center !important;
}

.align-self-start {
  align-self: start !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-end {
  text-align: end;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-2 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.m-1 {
  margin: 1rem;
}

.mx-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-4 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.my-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-10 {
  margin-top: 10rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.my-05 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.p-5 {
  padding: 5rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pt-5 {
  padding-top: 5rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pl-2 {
  padding-left: 2rem;
}

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-4 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.height-100 {
  min-height: 100%;
  height: 100%;
  max-height: 100%;
}

.w-10 {
  width: 10rem;
}

.w-100 {
  width: 100%;
}

hr {
  height: .05rem;
  background-color: var(--gray);
  border: none;
  margin: .1rem 0;
}

.font-weight-light {
  font-weight: lighter;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-bold {
  font-weight: bold;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

:root {
  --white: #fff;
  --whiteborder: #ddd;
  --lightwhite: #effbfe;
  --grayborder: #ededed;
  --lightgray: #aaa;
  --gray: #777;
  --black: #150605;
  --lightblue: #ace6ec;
  --lightred: #f47b6e;
  --red: #c26434;
}

body {
  min-height: 100vh;
  background-color: var(--white);
  flex-direction: column;
  margin: 0;
  font-family: Nunito, Helvetica, sans-serif;
  display: flex;
  overflow-x: hidden;
}

.body-container {
  flex-grow: 1;
}

@media screen and (max-width: 768px) {
  .body-container {
    min-height: 100vh;
  }

  .content {
    background-color: var(--primary-color);
    color: var(--text-color);
    flex-direction: column;
    flex: 100%;
    display: flex;
  }

  .router-view {
    background-color: var(--accent-color);
    flex-grow: 1;
    padding: 1rem 0;
  }

  .container {
    margin: auto;
    padding: 3rem 2rem;
  }
}

@media only screen and (max-width: 576px) {
  .container {
    margin: auto;
    padding: 2rem 1rem;
  }
}

/*# sourceMappingURL=index.4adf9851.css.map */
