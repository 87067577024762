.bg-white {
  background-color: var(--white);
}
.bg-lightwhite {
    background-color: var(--lightwhite);
}

.text-white {
  color: var(--white);
}
.text-lightwhite {
    color: var(--lightwhite);
}
.text-gray {
  color: var(--gray);
}
.text-black {
    color: var(--black);
}
.text-red {
    color: var(--red);
}