.map-header {
    width: 90vw;
    margin: 1rem auto;
}

.map-container {
    border-top: 1px solid var(--whiteborder);
    border-bottom: 1px solid var(--whiteborder);
    width: 90vw;
    height: 400px;
    margin: 0 auto 2rem auto;
}

@media only screen and (max-width: 768px) {
    .map-container {
        border-top: 1px solid var(--whiteborder);
        border-bottom: 1px solid var(--whiteborder);
        width: 90vw;
        height: 500px;
        margin: 0 auto 2rem auto;
    }
}