.services {
    /* Placement */
    margin-right: auto;
    margin-left: auto;
    min-width: 80vw;
    max-width: 80vw;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    /* Display */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 2rem;
  }
  .img-services-main {
      width: 8rem;
      height: auto;
      margin: 0.5rem 1.2rem 1.5rem 1.2rem;
  }
  @media only screen and (max-width: 768px) {
    .img-services-main {
      width: 6rem;
      height: auto;
      margin: 0.5rem 1.2rem 1.5rem 1.2rem;
    }
  }
  @media only screen and (max-width: 576px) {
      .services {
        /* Placement */
        margin-right: auto;
        margin-left: auto;
        min-width: 95vw;
        max-width: 95vw;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        /* Display */
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        gap: 1rem;
      }
      .img-services-main {
          width: 7rem;
          height: auto;
          margin: 0.5rem 1.2rem 1.5rem 1.2rem;
      }
  }