.h1-about {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.h2-about {
    padding-top: 2rem;
}

.h4-about {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: normal;
}

.about-title {
    font-size: 3rem;
    padding-left: 2.5rem;
    margin-bottom: 1.5rem;
}

.btn-about-contact {
    margin: 1rem auto 2rem auto;
    padding: 1rem;
    background-color: var(--red);
    color: var(--white);
    border-radius: 2rem;
    text-decoration: none;
}

.word-wrap-about-description {
    word-wrap: break-word;
    width: 35vw;
    font-size: 1.5rem;
    font-weight: normal;
}

.word-wrap-about-value {
    word-wrap: break-word;
    width: 50vw;
    font-weight: normal;
    text-align: center;
}


@media only screen and (max-width: 768px) { 
    .word-wrap-about-description {
        word-wrap: break-word;
        width: 50vw;
    }
    .word-wrap-about-value {
        word-wrap: break-word;
        width: 50vw;
        font-weight: normal;
        text-align: center;
    }
}

@media only screen and (max-width: 576px) { 
    .word-wrap-about-description {
        word-wrap: break-word;
        width: 80vw;
    }
    .word-wrap-about-value {
        word-wrap: break-word;
        width: 80vw;
        font-weight: normal;
        text-align: center;
    }
}