/* home-products */
.home-products {
    background-color: var(--lightwhite);
}
.home-products-list {
    /* Placement */
    margin-right: auto;
    margin-left: auto;
    min-width: 80vw;
    max-width: 80vw;
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* Display */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 2rem;
}
.home-products-intro {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-product-info {
    display: flex;
    flex-direction: column;
}
.btn-view-home-products {
    margin: 1rem auto 2rem auto;
    padding: 1rem;
    background-color: var(--red);
    color: var(--white);
    border-radius: 2rem;
    text-decoration: none;
}
/* /home-products */

/* Tablet Devices */
@media only screen and (max-width: 768px) {
    /* home-products */
    .home-products-list {
      /* Placement */
      margin-right: auto;
      margin-left: auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
      /* Display */
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto auto;
      gap: 2rem;
    }
    /* /home-products */
  }
  /* /Tablet Devices */
  
  /* Mobile Devices */
  @media only screen and (max-width: 576px) {
    /* home-products */
    .home-products-list {
      /* Placement */
      margin-right: auto;
      margin-left: auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
      /* Display */
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto auto auto auto auto auto;
      gap: 2rem;
    }
    /* /home-products */
  }
  /* /Mobile Devices */