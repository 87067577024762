.fade-in {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
}
.sidebar-slide {
    animation: sidebarSlide ease 0.6s;
    -webkit-animation: sidebarSlide ease 0.6s;
}

@media screen and (max-width: 768px) {
    /* same as width where there is no sidebar */
    .navbar-slide {
        animation: navbarSlide ease 0.4s;
        -webkit-animation: navbarSlide ease 0.4s;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes toastfadein {
    from {
        right: 0;
        opacity: 0;
    }
    to {
        right: 30px;
        opacity: 1;
    }
}
@-webkit-keyframes toastfadein {
    from {
        right: 0;
        opacity: 0;
    }

    to {
        right: 30px;
        opacity: 1;
    }
}

@keyframes toastfadeinmobile {
    from {
        top: 0;
        opacity: 0;
    }

    to {
        top: 10vh;
        opacity: 1;
    }
}
@-webkit-keyframes toastfadeinmobile {
    from {
        top: 0;
        opacity: 0;
    }

    to {
        top: 10vh;
        opacity: 1;
    }
}

@keyframes zoom {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

@-webkit-keyframes zoom {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

@keyframes navbarSlide {
    0% {
        margin-top: -70px;
    }

    100% {
        margin-top: 0;
    }
}
@-webkit-keyframes navbarSlide {
    0% {
        margin-top: -70px;
    }

    100% {
        margin-top: 0;
    }
}

@keyframes sidebarSlide {
    0% {
        margin-left: -40%;
    }

    100% {
        margin-left: 0;
    }
}
@-webkit-keyframes sidebarSlide {
    0% {
        margin-left: -40%;
    }

    100% {
        margin-left: 0;
    }
}

@keyframes rotate {
  to { 
    transform:rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  to { 
    transform:rotate(360deg);
  }
}