h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

a {
  text-decoration: none;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}
.flex-grow-0 {
  flex-grow: 0;
}
.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-2 {
  flex-grow: 2;
}
.flex-grow-3 {
  flex-grow: 3;
}
.flex-grow-4 {
  flex-grow: 4;
}
.flex-gap-1 {
  gap: 1rem;
}
.flex-gap-2 {
  gap: 2rem;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-stretch {
  justify-content: stretch !important;
}

.justify-content-end {
  justify-content: end;
}

.align-items-center {
  align-items: center !important;
}
.align-items-stretch {
  align-items: center !important;
}
.align-self-start {
  align-self: start !important;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-end {
  text-align: end;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.mx-2 {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}

.m-1 {
  margin: 1rem;
}

.mx-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mx-4 {
  margin-right: 4rem;
  margin-left: 4rem;
}

.my-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}


.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-4 {
  margin-top: 4rem;
}

.mt-10 {
  margin-top: 10rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.my-05 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}

.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.p-5 {
  padding: 5rem;
}

.pt-1 {
  padding-top: 1rem;
}
.pt-2 {
  padding-top: 2rem;
}
.pt-5 {
  padding-top: 5rem;
}
.pb-1 {
  padding-bottom: 1rem;
}
.pb-2 {
  padding-bottom: 2rem;
}
.pb-3 {
  padding-bottom: 3rem;
}
.pl-2 {
  padding-left: 2rem;
}

.px-1 {
  padding-right: 1rem;
  padding-left: 1rem;
}
.px-2 {
  padding-right: 2rem;
  padding-left: 2rem;
}
.px-3 {
  padding-right: 3rem;
  padding-left: 3rem;
}
.px-4 {
  padding-right: 4rem;
  padding-left: 4rem;
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.py-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.height-100 {
  min-height: 100%;
  height: 100%;
  max-height: 100%;
}

.w-10 {
  width: 10rem;
}
.w-100 {
  width: 100%;
}

hr {
  height: 0.05rem;
  margin: 0.1rem 0;
  background-color: var(--gray);
  border: none;
}

.font-weight-light {
  font-weight:lighter;
}
.font-weight-normal {
  font-weight: normal;
}
.font-weight-bold {
  font-weight: bold;
}