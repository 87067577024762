form {
    padding: 1rem;
}

label {
    display: block;
    margin-bottom: 5px;
}

input,
select {
    padding: 10px 15px;
    width: 100%;
    outline: 0;
    margin-bottom: 20px;
    border-radius: 20px;
    border: 0;
    background-color: var(--grayborder);
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.textarea-div {
    width: 100%;
}

textarea {
    padding: 10px;
    width: 99%;
    outline: 0;
    margin-bottom: 20px;
    border-radius: 20px;
    border: 0;
    background-color: var(--grayborder);
    font-size: 14px;
    height: 100px;
}

.submit-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.btn {
    background-color: var(--red);
    color: var(--white);
    padding: 12px 15px;
    width: 100%;
    margin: auto;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-radius: 20px;
    font-weight: 200;
    transition: 0.3s all ease;
}

.btn:hover {
    cursor: pointer;
    background-color: var(--lightred);
}

.btn:focus {
    outline: none;
}

.send-icon {
    fill: white;
    width: 1rem;
    height: auto;
    display: inline;
    padding-left: 0.2rem;
}

.check-icon {
    fill: white;
    width: 1.2rem;
    height: auto;
    display: inline;
}


/* Big Screens */
@media screen and (min-width: 768px) {
    .large-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .small-group {
        width: calc(100% - 1rem);
    }
}

/* /Big Screens */

/* Tablet Devices */
@media screen and (max-width: 768px) {
    .contact-form {
        background-color: var(--white);
        border: 1px solid var(--whiteborder);
        border-radius: 1rem;
        padding: 2rem;
    }

    input,
    select {
        width: calc(100% - 1rem);
    }
}

/* /Tablet Devices */