/* The Modal (background) */
.app-modal {
    display: none;
}
.ui-modal {
    position: fixed; /* Stay in place */
    z-index: 999; /* Sit on top */
    padding-top: 10vh;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.6); /* Black w/ opacity */
}
/* Modal title*/
.ui-modal-title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 1rem 1rem 1rem; 
}
/* Modal Content */
.ui-modal-content {
    position: relative;
    margin: auto;
    display: block;
    width: 90%;
    max-width: 70vw;
}
/* Caption of Modal - Same Width as the content */
.ui-modal-caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: var(--hover-color);
    padding: 2rem 0;
}
/* Add Animation - Zoom in the Modal */
.ui-modal-content, #caption {
    animation-name: zoom;
    animation-duration: 0.3s;
}

/* The Close Button */
.ui-modal-close {
    color: var(--text-color);
    font-size: 2rem;
    font-weight: bold;
    transition: 0.3s;
    z-index: 10;
}

.ui-modal-close:hover,
.ui-modal-close:focus {
    color: var(--error-color);
    text-decoration: none;
    cursor: pointer;
}

@media screen and (max-width: 576px) {
    .ui-modal-title-wrapper {
        flex-direction: column;
    }
}