.spinner {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background-color: transparent;
    margin: 0 auto;
}

.rotate {
    border-top: 2px solid #fff;
    border-right: 2px solid transparent;
    animation: rotate 800ms linear infinite;
}