.contact-page {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: auto;
}

.bg-contact {
    position: relative;
    background-color: #3f3d56;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: auto;
    overflow-x: hidden;
    color: var(--white);
}

.bg-contact-md {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 2rem;
    padding-left: 2rem;
    border-left: 0.1px solid var(--whiteborder);
}

.contact-container {
    padding: 3rem 5rem;
    margin: auto;
}
.contact-container h1, .contact-container h4 {
    padding-bottom: 1rem;
}

.company-information {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto;
    gap: 3rem;
    justify-content: start;
    align-items: center;
    text-align: left;
    padding: 3rem 1rem 0 1rem;
}

.contact-link {
    text-decoration: none;
    color: var(--white);
}

.contact-link:hover {
    text-decoration: none;
    color: var(--whiteborder);
}

.contact-icon {
    fill: var(--red);
    height: 3rem;
    width: auto;
}

.subscribe-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.svg-subscribe {
    width: auto;
    height: 11rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
}



@media only screen and (max-width: 768px) {
    .contact-page {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        position: relative;
        padding-bottom: 2rem;
        background-color: var(--lightred);
        background: url("../assets/svg/undraw_contact_us.svg") no-repeat center center;
        background-size: cover;
        background-position: center;
        overflow-x: hidden;
    }

    .bg-contact {
        position: relative;
        background-color: #3f3d56;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        height: auto;
        width: auto;
        overflow-x: hidden;
        color: var(--white);
    }

    .bg-contact-md {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 3rem;
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .contact-icon {
        fill: var(--red);
        height: 2rem;
        width: auto;
    }

    .company-information {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto auto;
        gap: 2rem;
        justify-content: center;
        align-items: center;
        text-align: left;
        padding: 1rem;
    }

    .svg-subscribe {
        position: absolute;
        bottom: 1%;
        left: 15%;
        width: 50%;
        height: 0;
        overflow-x: hidden;
    }
}

@media only screen and (max-width: 576px) {
    .contact-container {
        padding: 1rem;
        margin: auto;
    }
}