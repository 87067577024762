.bg-product-intro-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: var(--lightred);
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../assets/img/product-landing.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 90vh;
    height: auto;
    width: auto;
    margin: auto;
    overflow-x: hidden;
}

.img-product-intro-main {
    width: auto;
    height: 10rem;
    margin: 0.5rem 1.2rem 1.5rem 1.2rem;
}

.word-wrap-product-intro-landing {
    word-wrap: break-word;
    width: 80vw;
}

.product-intro-description {
    word-wrap: break-word;
    width: 40vw;
    font-size: 2rem;
}

.btn-product-action {
    margin: 1rem 0 2rem 0;
    padding: 0.5rem;
    min-width: 30rem;
    max-width: 30rem;
    text-align: center;
    background-color: var(--red);
    color: var(--white);
    border-radius: 2rem;
    text-decoration: none;
}

.products {
    background-color: var(--lightwhite);
}

.products-title {
    font-size: 5rem;
    margin-top: 0;
    margin-bottom: 0;
}

.product-title {
    font-size: 5rem;
    margin-top: 0;
    margin-bottom: 0;
}

.product-info {
    display: flex;
    flex-direction: column;
}

.product-card {
    background-color: var(--white);
    border: 1px solid var(--lightblue);
    border-radius: 1rem;
    padding: 0.5rem 0.5rem 1rem 1.5rem;
}

.product-card:hover {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.product-card-img {
    text-align: center;
    margin: auto;
    width: auto;
    max-width: 90%;
    height: 11rem;
}

.products-intro {
    min-height: 80vh;
    background-color: var(--lightred);
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../assets/img/product-landing.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: var(--white);
}

.products-list {
    /* Placement */
    margin-right: auto;
    margin-left: auto;
    min-width: 80vw;
    max-width: 80vw;
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* Display */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 2rem;
}

.products-product-intro-description {
    word-wrap: break-word;
    width: 80vw;
    font-weight: normal;
    margin-bottom: 1rem;
}

.contact-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 1rem;
}

.btn-contact {
    margin: 1rem auto;
    padding: 1rem 1.5rem;
    background-color: var(--red);
    color: var(--white);
    border-radius: 2rem;
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
    .bg-product-intro-main {
        position: relative;
        background-color: var(--lightred);
        background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../assets/img/product-landing-medium.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 90vh;
        height: auto;
        width: auto;
        margin: auto;
        overflow-x: hidden;
    }

    .img-product-intro-main {
        width: auto;
        height: 8rem;
        margin: 0.5rem 1.2rem 1.5rem 1.2rem;
    }

    .word-wrap-product-intro-landing {
        word-wrap: break-word;
        width: 70vw;
    }

    .product-intro-description {
        word-wrap: break-word;
        width: 60vw;
    }

    .btn-product-action {
        margin: 1rem 0 2rem 0;
        padding: 0.5rem;
        min-width: 30rem;
        max-width: 30rem;
        text-align: center;
        background-color: var(--red);
        color: var(--white);
        border-radius: 2rem;
        text-decoration: none;
    }

    .products-list {
        margin-right: auto;
        margin-left: auto;
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto auto;
        gap: 2rem;
    }

    .products-product-intro-description {
        word-wrap: break-word;
        width: 60vw;
        font-weight: normal;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 576px) {
    .bg-product-intro-main {
        position: relative;
        background-color: var(--lightred);
        background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../assets/img/product-landing-small.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 90vh;
        height: auto;
        width: auto;
        margin: auto;
        overflow-x: hidden;
    }

    .img-product-intro-main {
        width: auto;
        height: 10rem;
        margin: 0.5rem 1.2rem 1.5rem 1.2rem;
    }

    .word-wrap-product-intro-landing {
        word-wrap: break-word;
        width: 90vw;
    }

    .product-intro-description {
        word-wrap: break-word;
        width: 80vw;
    }

    .btn-product-action {
        margin: 1rem auto 2rem auto;
        padding: 0.5rem;
        min-width: 70vw;
        max-width: 70vw;
        text-align: center;
        background-color: var(--red);
        color: var(--white);
        border-radius: 2rem;
        text-decoration: none;
    }

    .product-card-img {
        text-align: center;
        margin: auto;
        margin-top: 1rem;
        margin-bottom: 2rem;
        padding: 0 !important;
        width: auto;
        max-width: 90%;
        height: 11rem;
    }

    .products-intro {
        min-height: 80vh;
        background-color: var(--lightred);
        background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('../assets/img/product-landing-small.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        color: var(--white);
    }

    .products-title {
        font-size: 5rem;
        margin: auto;
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
    }

    .products-list {
        margin-right: auto;
        margin-left: auto;
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto auto auto auto;
        gap: 2rem;
    }

    .products-product-intro-description {
        word-wrap: break-word;
        width: 90vw;
        margin: auto;
        font-weight: normal;
        margin-bottom: 1rem;
    }
}